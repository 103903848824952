import { Card, Paper, Title, Image, Text} from "@mantine/core";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function ConnectPanel() {
    return (
        <div style={{
            
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            textAlign: 'center'
        }}>
          <div style={{
            display: 'flex',
          }}
          >  <Image 
          style = {{
            marginRight: 50}}src={"/DUPA.gif"} width={50} /> 
            <Image style = {{
            marginLeft: 50}}src={"/DUPA2.gif"} width={50} /> 
            </div>
            <Card shadow="sm" maw={600} padding={50}>
            <br/>
            <Image src={'./preview.gif'} />
            
            <br/>
                <Title></Title>
                <Text>There is no Hash I love You</Text>
                <br />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                    <ConnectButton />
                </div>
            </Card>
        </div>
    )
}