import { Card, Paper, Title, Text, Button, Image } from "@mantine/core";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  usePrepareContractWrite,
  useContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import { abi } from "./abi/abi";
import { parseEther } from "viem";
import { Select } from "@mantine/core";
interface Address {
  address: string;
}

export default function MintPanel({ address }: Address) {
  const [frenMintLoading, setFrenMintLoading] = useState<boolean>(false);
  const [mintLoading, setMintLoading] = useState<boolean>(false);
  const [hash, setHash] = useState<`0x${string}`>("0x");
  const [signature, setSignature] = useState<string>("");
  const [amount, setAmount] = useState<number>(1);
  const apiEndpoint = "https://ecdsahashlady-dev-ekgt.3.us-1.fl0.io/sig"; ///zmienić
  ///
  async function handleFrenMint() {
    try {
      setFrenMintLoading(true);
      frenWrite({
        args: [amount, signature],
        gas: BigInt(200000),
      });
    } catch (e) {
      console.log(e);
      setFrenMintLoading(false);
    }
  }

  async function handleMint() {
    try {
      setMintLoading(true);
      console.log(parseEther((0.0069 * amount).toString()));
      write({
        value: parseEther((0.0069 * amount).toString()),
        args: [amount],
        gas: BigInt(200000),
      });
    } catch (e) {
      console.log(e);
    }
  }

  const {
    data: txHashData,
    isError,
    isLoading: txIsLoading,
  } = useWaitForTransaction({
    hash: hash,
  });

  const { config: frenConfig, error: frenError } = usePrepareContractWrite({
    address: "0x486Bda3013FF013c0dAe9aBB2c8D234cAD9C5F7B",
    abi: abi, // to be filled
    functionName: "freeMint",
    chainId: 8453,
  });

  const {
    data: frenData,
    isLoading: frenIsLoading,
    isSuccess: frenIsSuccess,
    write: frenWrite,
  } = useContractWrite({
    address: "0x486Bda3013FF013c0dAe9aBB2c8D234cAD9C5F7B",
    abi: abi, // to be filled
    functionName: "freeMint",
    chainId: 8453,
    onSuccess: (data) => {
      setFrenMintLoading(false);
      setHash(data.hash);
    },
    onError: (e) => {
      setFrenMintLoading(false);
      alert("Error!" + e);
    },
  });

  const { config, error } = usePrepareContractWrite({
    address: "0x486Bda3013FF013c0dAe9aBB2c8D234cAD9C5F7B",
    abi: abi, // to be filled,
    chainId: 8453,
    functionName: "freeMint",
  });

  const { data, isLoading, isSuccess, write } = useContractWrite({
    address: "0x486Bda3013FF013c0dAe9aBB2c8D234cAD9C5F7B",
    abi: abi, // to be filled
    functionName: "mint",
    chainId: 8453,
    onSuccess: (data) => {
      setFrenMintLoading(false);
      setHash(data.hash);
    },
    onError: (error) => {
      setMintLoading(false);
      alert("Error!" + error);
    },
  });

  useEffect(() => {
    if (address) {
      axios
        .get(`${apiEndpoint}`, {
          params: {
            address,
          },
        })
        .then((response) => {
          console.log(response.data.sig);
          setSignature(response.data.sig);
          console.log(abi);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [address]);

  useEffect(() => {
    if (txHashData) {
      console.log("seems confirmed");
      console.log(txHashData);
      setFrenMintLoading(false);
      setMintLoading(false);
      alert("Minted!");
    }
  }, [txHashData]);

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        textAlign: "center",
      }}
    >
      <Card shadow="sm" maw={600} padding={50}>
        <Image src={"./preview.gif"} />
        <br />
        <Text>
          {signature === "Not a fren"
            ? ""
            : signature === ""
            ? "Checking eligility..."
            : "Winner detected: - free mint"}
        </Text>
        <br />
        <Select
          label="Select amount"
          placeholder="Pick one"
          value={amount.toString()}
          onChange={(value) => setAmount(parseInt(value!))}
          defaultChecked
          data={[
            {
                value: '1',
                label: '1'
            },
            {
                value: '2',
                label: '2'
            },
            {
                value: '3',
                label: '3'
            },
            {
                value: '4',
                label: '4'
            },
            {
                value: '5',
                label: '5'
            },
            {
                value: '6',
                label: '6'
            },
            {
                value: '7',
                label: '7'
            },
            {
                value: '8',
                label: '8'
            },
            {
                value: '9',
                label: '9'
            },
            {
                value: '10',
                label: '10'
            },
            {
                value: '11',
                label: '11'
            },
            {
                value: '12',
                label: '12'
            },
            {
                value: '13',
                label: '13'
            },
            {
                value: '14',
                label: '14'
            },
            {
                value: '15',
                label: '15'
            },
            {
                value: '16',
                label: '16'
            },
            {
                value: '17',
                label: '17'
            },
            {
                value: '18',
                label: '18'
            },
            {
                value: '19',
                label: '19'
            },
            {
                value: '20',
                label: '20'
            },
          ]}
        />
        <br/>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            textAlign: "center",
          }}
        >
          <Button
            loading={frenMintLoading}
            disabled={signature === "Not a fren" || signature === ""}
            size="lg"
            mr={10}
            onClick={handleFrenMint}
          >
            Friends Mint (Free)
          </Button>

          <Button size="lg" ml={10} loading={mintLoading} onClick={handleMint}>
            {`Mint (${0.0069 * amount} ETH)`}
          </Button>
        </div>
      </Card>
    </div>
  );
}
